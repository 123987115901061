.basicHeading {
  text-align: start;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  h1 {
    color: #1d1d1d;
    font-family: "Outfit";
    font-size: 68px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;

    // Media queries
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 120%;
    }
    @media (max-width: 426px) {
      font-size: 23px;
      line-height: 120%;
    }
  }
  .heading-color {
    color: #fff;
  }
  span {
    color: #ff9c07;
  }

  p {
    max-width: 1440px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: start;
    color: #0c304b;

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 130%;
    }
  }
  .before_after_para {
    color: #777;
    font-family: "Outfit";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: left;
    margin: 0 !important;
  }
  .border {
    width: 120px;
    height: 1px;
    margin: 24px 0px;

    border: 2px solid #dee8f0;
  }
}
.basicHeading-2 {
  align-items: flex-start;
  h1 {
    color: #18374c;
    font-family: "Outfit";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: normal;

    // Media queries
    @media (max-width: 991px) {
      font-size: 22px !important;
    }
    @media (max-width: 426px) {
      font-size: 18px !important;
    }
  }
  p {
    text-align: left;
  }
  .para-color {
    color: #f1f1f1;
  }
  .text-color {
    color: #ccc;
  }
}

.basicHeading-3 {
  align-items: center;
  h1 {
    text-align: center;
    font-size: 40px;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 800;
    line-height: 140%;

    // Media queries
    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 140%;
    }
    @media (max-width: 426px) {
      font-size: 18px;
      line-height: 140%;
    }
  }
  .para-3 {
    text-align: center;
  }
}

.heading {
  // font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
}

$maxWidth: 1440px;
$marginAuto: 0 auto;

.main-blog-page {
  width: 100%;

  .blog-heading {
    max-width: $maxWidth;
    margin: $marginAuto;
    padding: 90px 20px 50px;
    text-align: center;

    h1 {
      color: #ff9c07;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.96px;
      margin: 0 0 10px;
    }
    p {
      color: #000;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }

  .feature-card {
    width: 100%;
    background: #f6f7f9;
    display: flex;
    min-height: 406px;
    padding: 50px 0;
    flex-shrink: 0;

    .main-card {
      max-width: $maxWidth;
      margin: $marginAuto;
      padding: 0 100px;
      display: flex;
      align-items: center;
      gap: 50px;
      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
      @media (max-width: 768px) {
        padding: 0 20px;
      }
      &:hover {
        button {
          display: block !important;
        }
      }

      .card-img {
        width: 413.838px;
        height: 306.24px;
        flex-shrink: 0;
        position: relative;

        @media (max-width: 768px) {
          width: 100%;
        }

        button {
          display: none;
          width: auto;
          height: auto;
          border: none;
          background: transparent;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          cursor: pointer;
        }

        .logo-img {
          width: 48px;
          height: 28px;
          object-fit: cover;
          position: absolute;
          top: 15px;
          left: 25px;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-about {
        width: 592px;
        @media (max-width: 1260px) {
          width: 100%;
        }

        h3 {
          color: #0062e3;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.64px;
          margin: 0 0 15px;
        }
        p {
          color: #101729;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }

  .cards-wrapers {
    max-width: $maxWidth;
    margin: $marginAuto;
    padding: 84px 80px 60px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px 40px;

    @media (max-width: 768px) {
      padding: 84px 20px 60px;
    }
  }
}

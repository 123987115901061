@import "src/Assets/Scss/variables";
.toggleWrapperStyle {
  //background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  width: 94px;
  min-height: auto;

  .toggle-top-text {
    text-align: center;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    flex-direction: column;
  }
}

.ant-switch.ant-switch-checked {
  //background-color: #FFC107;
  background: none !important;
  border: 1px solid #ffc107 !important;
}
.ant-switch.ant-switch-checked:before {
  opacity: 0 !important;
}

.ant-switch .ant-switch-handle {
  top: 3px !important;
  width: 16px !important;
  height: 16px !important;
  inset-inline-start: 4px !important;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 20px) !important;
}

.ant-switch {
  background: white !important;
  height: 25px !important;
  width: 60px !important;

  .ant-switch-handle::before {
    background: #bbbdc0 !important;
    box-shadow: inherit !important;
  }
}

.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background: #ffc107 !important;
  background-image: radial-gradient(#ffc107, #ffc107 100%) !important;
}

.ant-switch .ant-switch-inner .ant-switch-inner-checked,
.ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked {
  color: #ffc107 !important;
  // margin-top: -1px !important;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  //background-color: #FFC107;
  background: transparent !important;
  border: 1px solid #ffc107 !important;
}

.ant-switch .ant-switch-checked:not(.ant-switch-disabled) {
  background-color: gray !important;
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: none !important;
  border: 1px solid #bbbdc0 !important;
}

.ant-switch {
  //background-color: #BBBDC0;
  border: 1px solid #bbbdc0 !important;
  cursor: pointer !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.spanToggleNumberStyle {
  color: #ffc107;
  text-align: center;

  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.paraToggleStyle {
  color: #5a5a5a;

  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 999px) {
  .toggleWrapperStyle {
    width: 120px;
  }

  .paraToggleStyle {
    font-size: 13px;
  }
}

.hero_container {
  width: 100%;
  height: 100%;
  background: #f6f7f9;
  .about_banner {
    position: relative;
    min-height: 616px;
    padding: 43px 150px 99px;
    @media screen and (max-width: 1200px) {
      padding: 43px 130px 99px;
    }
    @media screen and (max-width: 991px) {
      padding: 43px 80px 99px;
      img {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 40px 24px 50px;
      min-height: 560px;
      flex-direction: column;
      justify-content: flex-start;
      gap: 40px;
    }
    .about_card {
      max-width: 500px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
  .bg_challenge {
    width: 100%;
    background: #fdfdfd;

    .challenge {
      display: flex;
      position: relative;
      top: -40px;
      align-items: center;
      justify-content: center;
      gap: 60px;
      @media screen and (max-width: 991px) {
        gap: 40px;
        padding: 0 24px;
      }
      @media screen and (max-width: 768px) {
        gap: 20px;
        flex-direction: column;
        width: 100%;
      }
      .all_about {
        max-width: 410px;
        width: 100%;
        border-radius: 10px;
        padding: 37.5px 27.5px;
        background: #003580;
        box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.15);
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
        .all_about_heading {
          color: #fff;
          font-family: "Outfit";
          font-size: 20px;
          font-style: normal;
          margin: 0;
          font-weight: 600;
          line-height: 30.5px;
        }
        .all_about_text {
          color: #fff;
          font-family: "Outfit";
          font-size: 14px;
          font-style: normal;
          margin: 0;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.28px;
          margin-top: 10px;
        }
        .all_challenge_heading {
          color: #1d1d1d;
        }
        .all_challenge_text {
          color: #888;
          margin-top: 8px;
        }
      }
      .all_challenge {
        border-radius: 10px;
        background: #fafafa;
        padding: 8.5px 6px;
      }
    }
  }
  .our_vision {
    gap: 55px;
    padding: 48px 100px;
    @media screen and (max-width: 1200px) {
      padding: 48px 90px;
      gap: 50px;
    }
    @media screen and (max-width: 991px) {
      padding: 40px 80px;
      gap: 60px;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    @media screen and (max-width: 768px) {
      gap: 40px;
      padding: 40px 24px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .vision_card {
      max-width: 604px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
  .our_inner_building {
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .our_building {
    width: 100%;
    background: #f8f9fb;
  }
  .our_future {
    padding: 133px 120px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1200px) {
      padding: 100px;
    }
    @media screen and (max-width: 991px) {
      padding: 80px;
    }
    @media screen and (max-width: 768px) {
      padding: 40px 24px;
    }
    .future_card {
      max-width: 941px;
      margin-bottom: 45px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
    img {
      @media screen and (max-width: 1280px) {
        width: 100%;
      }
    }
  }
  .bg_support {
    background-image: url("../../Assets/Images/about-back-support.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 413px;
    .show_support {
      width: 100%;
      height: 100%;
      margin-bottom: 60px;
      background: linear-gradient(
        90deg,
        #003580 0%,
        rgba(0, 53, 128, 0.6) 100%
      );

      .our_support {
        align-items: flex-start;
        padding: 94px 100px 184px;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          padding: 60px 24px 60px;
        }
        .future_card {
          max-width: 734px;
          @media screen and (max-width: 768px) {
            max-width: 100%;
          }
        }
      }
    }
  }
  .connection_building {
    width: 100%;
    background: #fdfdfd;
    padding-bottom: 80px;
    @media screen and (max-width: 768px) {
      padding-bottom: 40px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .our_connection {
      padding: 60px 100px 180px;
      @media screen and (max-width: 1200px) {
        padding: 60px 80px 120px;
      }
      @media screen and (max-width: 991px) {
        padding: 60px 80px 100px;
      }
      @media screen and (max-width: 768px) {
        padding: 40px 24px 40px;
      }
      .connection_card {
        display: flex;
        align-items: flex-start;
        gap: 44px;
        flex-direction: row;
        @media screen and (max-width: 991px) {
          flex-direction: column-reverse;
          gap: 60px;
        }
        @media screen and (max-width: 768px) {
          gap: 40px;
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .explore_content {
          max-width: 460px;
          padding-top: 140px;
          @media screen and (max-width: 991px) {
            padding-top: 0px;
          }
          @media screen and (max-width: 768px) {
            max-width: 100%;
          }
          .connection_para {
            min-height: 242px;
            margin: 4px 0 0 -100px;
            padding: 17.5px 18.47px;
            border-radius: 6px;
            background: #eef2f5;
            color: #888;
            font-family: "Outfit";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.9px;
            @media screen and (max-width: 991px) {
              margin: 4px 0 0 0;
            }
            @media screen and (max-width: 768px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .our_status {
    padding: 0 100px 73.13px;
    gap: 65px;
    justify-content: flex-start;
    @media screen and (max-width: 991px) {
      padding: 0 80px 73px;
      gap: 60px;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    @media screen and (max-width: 768px) {
      padding: 0 24px 40px;
      gap: 40px;
    }
    .status_card {
      max-width: 472px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
  .our_join {
    max-width: 1200px;
    min-height: 330px;
    margin: 0 auto;
    border-radius: 14px;
    background: #003580;
    padding: 10px;
    .our_card {
      max-width: 648px;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      .view_btn {
        width: 100%;
        text-align: center;
        margin: 37px 0 0;
      }
    }
  }
  .our_commitment {
    max-width: 751px;
    padding: 74px 0;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
}
// common styles
.common {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.stand-alone-wrapper {
  width: 100%;
  min-height: 53px;
  padding: 18px;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  display: inline-flex;

  .text-box {
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    img {
      width: 19px;
      height: 19px;
      object-fit: cover;
    }
    .p {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.32px;
      word-wrap: break-word;
      margin: 0;
    }
  }

  .close-btn {
    width: 14px;
    height: 14px;
    object-fit: cover;
    cursor: pointer;
  }
}

@import "src/Assets/Scss/variables";
.completeFormSectionWrapper {
  background-color: #ffffff;
  padding: 75px 82px 92px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  .home-forms-section {
    width: 564px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }

  & p {
    color: #101729;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 40px;
  }
}
.phaseHeadingWrapper {
  font-size: 20px;
  color: #101729;
}

.firstFormSectionWrapper {
  margin-top: 50px;
}
.ListSectionWrapper {
  margin-bottom: 40px;
  ul {
    margin: 0;
    padding-left: 15px;

    @media (max-width: 700px) {
      padding-left: revert;
    }
  }
  ul li {
    list-style: none;
    color: #455467;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    color: #101729;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  ul li::before {
    content: "\2022";
    color: #47af22;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -16px;
  }
}

.checksApplyWrapperStyle {
  & h4 {
    color: #101729;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-bottom: 18px;
  }
  p {
    color: #101729;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 600px) {
  .completeFormSectionWrapper {
    padding: 75px 20px 92px;
  }

  .checksApplyWrapperStyle {
    & h4 {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .phaseHeadingWrapper {
    font-size: 14px;
    color: #101729;
  }
}

@media screen and (max-width: 400px) {
  .completeFormSectionWrapper {
    //background-color: red;
    padding: 52px 20px 62px;
  }

  .checksApplyWrapperStyle {
    margin-top: 30px;
    margin-left: 0px;
  }
  .phaseHeadingWrapper {
    font-size: 14px;
    color: #101729;
  }

  .ListSectionWrapper {
    margin-left: -20px;

    ul li {
      list-style: none;

      font-size: 12px;
      padding-top: 20px;
      color: #101729;
    }
    ul li::before {
      content: "\2022";
      color: #47af22;
      font-size: 20px;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Outfit:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&family=Suwannaphum:wght@300;400;700;900&display=swap");

@font-face {
  font-family: "Times New Roman"; /*Can be any text*/
  src: local("AirbnbCerealLight"), url("../../Assets/Fonts/TimesNewRoman/times\ new\ roman.ttf") format("truetype");
}
@font-face {
  font-family: Arial; /*Can be any text*/
  src: local("AirbnbCerealLight"), url("../../Assets/Fonts/Arial/ArialTh.ttf") format("truetype");
}

body {
  font-family: "Inter";
  scroll-behavior: smooth;
}

// Main Heading
.main_heading {
  color: #333;
  font-family: Arial;
  font-size: 32px;
  font-style: normal;
  margin: 0 0 32px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  // No Margin Bottom Main Heading
  &.main_heading_no_margin {
    margin: 0;
  }
}

// Sub Heading
.sub_heading {
  color: #333;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 24px;
  line-height: normal;
  letter-spacing: 0.48px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

// Paragraph
.main_para {
  color: #666;
  font-family: Arial;
  margin: 0 0 24px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  letter-spacing: 0.4px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

// Contact Links
.link_box {
  display: flex;
  gap: 6px;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    margin: 0 0 6px;
  }
  .link_para {
    margin: 0 0 5px;
  }
  .homeschool_site_link {
    font-family: Arial;
    cursor: pointer;
    color: #0062e3;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    margin: 0;
    display: inline-block;
    width: 100%;
    word-break: break-all;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.main_container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 70px 120px 0 !important;
  background: #fff;
  @media screen and (max-width: 768px) {
    padding: 70px 24px 0px !important;
  }
  .italic_heading {
    color: #666;
    margin: 0 0 24px;
    font-family: Arial;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .table_container {
    margin-bottom: 24px;
    .table_content {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-bottom: 8px;
    }
    .intro_content {
      margin: 24px 0 0;
    }
  }

  // Real Estate Page Contact Heading
  .info_sub_heading {
    font-size: 20px;
    margin: 0 0 32px;
  }

  //
  .foster_para {
    color: #000;
  }

  //
  .lisa_text {
    color: #000;
    font-weight: 700;
  }
}

// common styling
.no_margin_text {
  margin: 0;
}

// button
.button_div {
  width: 100%;
  padding: 97px 0 92px;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 50px 0 70px;
  }
}

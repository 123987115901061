.rentalCardComponentSection {
  box-shadow: -10px 10px 25px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 295px;
  background: #ffffff;
  padding: 17px 16px 20px;
}
.rentalImageWrapper {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 181px;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    font-family: "Times New Roman";
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    color: #c7c7c7;
    margin-top: 20px;
  }
}

.rentalCardHeading {
  width: 100%;
  //background: red;
  margin: 33px 0 28px;
  text-align: center;

  & p {
    color: #101729;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.rentalDetailsWrapper {
  //background: mediumspringgreen;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.rentalDetailContentWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & img {
    width: 21px;
    height: 23px;
    margin-bottom: 5px;
  }

  & p {
    color: #101729;
    font-family: "Times New Roman";
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
}

@media screen and (max-width: 1250px) {
  .rentalCardComponentSection {
    box-shadow: 1px 3px 30px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 300px;
    background: #ffffff;
    padding: 20px;
  }
}

@media screen and (max-width: 1120px) {
  .rentalCardComponentSection {
    box-shadow: 1px 3px 30px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 250px;
    background: #ffffff;
    padding: 20px;
  }
  .rentalDetailsWrapper {
    //background: mediumspringgreen;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .rentalDetailContentWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & img {
      width: 30px;
    }

    & p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 975px) {
  .rentalCardComponentSection {
    box-shadow: 1px 3px 30px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 340px;
    background: #ffffff;
    padding: 20px;
  }
  .rentalDetailsWrapper {
    //background: mediumspringgreen;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    //flex-wrap: wrap;
    width: 100%;
  }

  .rentalDetailContentWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & img {
      width: 30px;
    }

    & p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .rentalCardComponentSection {
    box-shadow: 1px 3px 30px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 400px;
    background: #ffffff;
    padding: 15px;
  }
  .rentalDetailsWrapper {
    //background: mediumspringgreen;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }

  .rentalDetailContentWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & img {
      width: 30px;
    }

    & p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
    }
  }
}

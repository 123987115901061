.articleScreenSection {
  background-color: #ffffff;
  padding: 80px 70px 70px;
  max-width: 1440px;
  margin: 0 auto;
}
.linkBackStyle {
  text-decoration: none;
}
.backWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  //background-color: red;

  & p {
    padding-left: 10px;
    color: #455467;
  }
}

.backIcon {
  margin-top: -12px;
}

.articleHeadingMainWrapper {
  margin-top: 0;

  & h3 {
    color: #101729;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & h4 {
    color: #003580;
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-top: 20px;
  }
  & p {
    color: #455467;
    font-size: 16px;
    margin-top: 60px;
    font-weight: 600;
  }
}

//Article Para section styling start

.articleParaSectionStart {
  .articleParaOwnSection {
    margin-top: 50px;

    & h3 {
      color: #455467 !important;
      font-family: Arial;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    & h2 {
      color: #455467;
      font-family: Arial;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    & p {
      color: #7b89a5;
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

//Article Image Section

.articleImageSectionMain {
  margin-top: 50px;

  .articleImageText {
    color: #fff;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.74px;
    width: 700px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}

.articleImageWrapper {
  //height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 61px;
  width: 100%;
  min-height: 726px;
  padding: 119px 16px 16px 109px;

  background-image: url("../../Assets/Images/articleImage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    min-height: 400px;
    padding: 62px 16px 26px 24px;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    padding: 82px 16px 16px 78px;
  }
}

.articleImageButtonWrapper {
  background-color: rgba(255, 185, 134, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 43px 61px;
  border-radius: 10px;
  color: #fff;
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.74px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;

  @media (max-width: 992px) {
    padding: 28px 32px;
    font-size: 24px;
  }
}

.articleImageSectionMain p {
  color: #7b89a5;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

//Article Date Section start
.articleDatesSectionWrapper {
  border-bottom: 1px solid #003580;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 50px;
}

.articleDateWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  margin-bottom: 14px;

  & p {
    color: #101729;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  & .dateparaStyle {
    color: #848484;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
}

.articleTimeWrapper {
  & p {
    color: #101729;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 14px;
  }
}

@media screen and (max-width: 767px) {
  .articleScreenSection {
    background-color: #ffffff;
    padding: 50px 20px 20px;
  }
  .articleHeadingMainWrapper {
    margin-top: 0;

    & h4 {
      font-size: 16px;

      color: #003580;
      font-weight: bold;
    }
    & h3 {
      color: #101729;
      font-size: 30px;
      font-weight: bold;

      margin-top: 20px;
    }
    & p {
      color: #455467;
      font-size: 14px;
      margin-top: 60px;
      font-weight: 600;
    }
  }

  .articleDatesSectionWrapper {
    border-bottom: 1px solid #003580;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 24px;
  }

  .articleDateWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    & p {
      font-size: 14px;
      font-weight: 500;
      margin-right: 40px;

      color: #101729;
    }

    & .dateparaStyle {
      color: #848484 !important;
    }
  }

  .articleTimeWrapper {
    & p {
      font-size: 14px;
      font-weight: 500;
      margin-right: 0px;

      color: #101729;
    }
  }

  .articleImageSectionMain p {
    color: #7b89a5;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .articleParaSectionStart {
    .articleParaOwnSection {
      margin-top: 50px;

      & h2 {
        color: #455467;
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      & p {
        color: #7b89a5;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .articleScreenSection {
    background-color: #ffffff;
    padding: 0 20px 20px;
  }
  .articleHeadingMainWrapper {
    margin-top: 0;

    & h4 {
      font-size: 12px;

      color: #003580;
      font-weight: bold;
    }
    & h3 {
      color: #101729;
      font-size: 20px;
      font-weight: bold;

      margin-top: 20px;
    }
    & p {
      color: #455467;
      font-size: 12px;
      margin-top: 30px;
      font-weight: 600;
    }
  }

  .articleDatesSectionWrapper {
    border-bottom: 1px solid #003580;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 40px;
  }

  .articleDateWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    & p {
      font-size: 12px;
      font-weight: 500;
      margin-right: 40px;

      color: #101729;
    }

    & .dateparaStyle {
      color: #848484 !important;
    }
  }

  .articleTimeWrapper {
    & p {
      font-size: 12px;
      font-weight: 500;
      margin-right: 0px;

      color: #101729;
    }
  }

  .articleImageSectionMain p {
    color: #7b89a5;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

$color_1: #0062e3;
$color_2: #999999;
$color_3: #757575;
$background-color_1: #ffffff;
$border-color_1: #dddddd;

.blog-card {
  width: 400px;
  min-height: 600px;
  border-radius: 7px 7px 2.5px 2.5px;
  border: 0.8px solid #ddd;
  overflow: hidden;

  @media (max-width: 800px) {
    width: 100%;
  }

  &:hover {
    button {
      display: block !important;
    }
  }

  .card-img {
    width: 100%;
    height: 252.719px;
    flex-shrink: 0;
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
    }

    button {
      display: none;
      width: auto;
      height: auto;
      border: none;
      background: transparent;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      cursor: pointer;
    }

    .logo-img {
      width: 48px;
      height: 28px;
      object-fit: cover;
      position: absolute;
      top: 15px;
      left: 25px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-about {
    padding: 20px 15px;
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;

    .heading {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      text-align: left;

      .text-wrapper {
        color: $color_1;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        margin: 0;
      }

      .coming-soon-TBD {
        color: $color_2;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        white-space: nowrap;
        width: fit-content;
        text-transform: uppercase;
        margin: 0;
      }
      .card-label {
        color: $color_2;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        white-space: nowrap;
        width: fit-content;
        text-transform: uppercase;
        margin: 8px 0;
      }
    }
    .para-text {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 20px;
      .p {
        color: $color_3;
        font-size: 15px;
        font-weight: 400;
        min-height: 120px;
        letter-spacing: 0;
        line-height: normal;
        margin: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .listen-now {
        color: $color_1;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        white-space: nowrap;
        width: fit-content;
      }
    }
  }
}

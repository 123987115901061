.contact_para {
  margin: 24px 0 0;
}
.homeschool_link {
  font-family: Arial;
  cursor: pointer;
  color: #0062e3;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

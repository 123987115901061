@import "../../Assets/Scss/variables";

.westleCardWrapper {
  width: 297px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centerWrap {
  width: 392px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centerWrap .westleCardHeading {
  margin-bottom: 50px;
}
.westleCardHeading {
  font-size: 20px;
  color: #6d767e;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}

.westleImageWrapper {
  width: 100%;
  //height: 270px;
}
.westleImageWrapper img {
  width: 100%;
  border-radius: 20px;
  //z-index: 99;
  box-shadow: 2px 20px 20px rgba(0, 0, 0, 0.2);
}

.westleFooterConentWrapper {
  width: 100%;
  background: transparent;
  //border-radius: 20px 20px 20px 0px;

  padding-top: 0px;

  padding-left: 0px;
  padding-right: 0px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.westleWhiteWrapper {
  background: white;
  padding-bottom: 30px;
  width: 90%;
  border-radius: 0px 0px 20px 20px;
  box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.2);
}
.centerWrapWhite .classesCotentWrapper {
  & p {
    font-size: 16px;
  }
}
.centerWrapWhite .locationWrapper {
  & p {
    color: #6d767e;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
  }
}
.classesCotentWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  //background: red;

  & p {
    font-size: 12px;

    font-weight: 600;
    color: #455467;
  }
  & span {
    font-size: 26px;

    font-weight: 600;
    margin-top: -31px;
    color: #455467;
  }
}

.locationWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

  & p {
    color: #6d767e;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1180px) {
  .westleCardWrapper {
    width: 260px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .centerWrap {
    width: 350px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .centerWrapWhite .locationWrapper {
    & p {
      color: #6d767e;
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .classesCotentWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    //background: red;

    & p {
      font-size: 12px;

      font-weight: 600;
      color: #455467;
    }
    & span {
      font-size: 26px;

      font-weight: 600;
      margin-top: -21px;
      color: #455467;
    }
  }

  .locationWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    & p {
      color: #6d767e;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1040px) {
  .westleCardWrapper {
    width: 260px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .centerWrap {
    width: 350px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .centerWrapWhite .locationWrapper {
    & p {
      color: #6d767e;
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .classesCotentWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    //background: red;

    & p {
      font-size: 12px;

      font-weight: 600;
      color: #455467;
    }
    & span {
      font-size: 26px;

      font-weight: 600;
      margin-top: -21px;
      color: #455467;
    }
  }

  .locationWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    & p {
      color: #6d767e;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 880px) {
  .westleCardWrapper {
    width: 350px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .centerWrap {
    width: 350px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .westleCardWrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .centerWrap {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.btn {
  display: inline-flex;
  text-decoration: none;
  padding: 18px 46px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: #003580;
  box-shadow: 4px 3px 10px -2px rgba(0, 0, 0, 0.25);
  .btn_text {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    line-height: normal;
    letter-spacing: 0.32px;
  }
  .text_color {
    color: #003580;
    text-align: center;
    font-family: "Outfit";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    line-height: normal;
    // Media queries
    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 140%;
    }
    @media (max-width: 426px) {
      font-size: 18px;
      line-height: 140%;
    }
  }
}
.bg-color {
  background: #ff9c07;
}
.bgWhite {
  border-radius: 6px;
  background: #fff;
  padding: 5px 38px;
}
.disabled {
  opacity: 0.3;
}

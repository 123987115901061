.formal-main-wrapper {
  max-width: 1440px;
  margin: 0 auto;

  .formal-content {
    margin-top: 121px;
    @media (max-width: 768px) {
      margin-top: 62px;
    }
  }

  .form-box {
    .form_container {
      padding: 0;

      .sigPad-design {
        display: flex;
        flex-direction: column;
        width: 432px;
        gap: 20px;
        position: relative;

        @media (max-width: 668px) {
          width: 100%;
        }

        button {
          position: absolute;
          top: 6px;
          right: 6px;
          width: fit-content;
          padding: 4px;
          background: #fff;
          border: none;
          outline: none;
          cursor: pointer;
          border-radius: 6px;

          img {
            width: 16px;
            height: auto;
          }
        }
      }

      .input-fields {
        margin-bottom: 39px;
        .input-box {
          display: flex;
          flex-direction: column;
          label {
            color: rgba(16, 23, 41, 0.5);
            font-family: Arial;
            font-size: 12px;
            margin: 23px 0 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }
          input {
            max-width: 564px;
            height: 39px;
            border: none;
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
            flex-shrink: 0;
            border-radius: 5px;
            background: #fff;
            padding: 12px;
          }
          .error {
            padding: 4px 0 0;
            color: red;
            font-size: 12px;
          }
        }
      }
      .checkbox-box {
        margin-bottom: 42px;
        .form_sub_heading {
          color: #101729;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          margin: 0;
          line-height: normal;
          letter-spacing: 0.48px;
        }
        .checkbox {
          margin: 16px 0 0;
          display: flex;
          align-items: center;
          gap: 12px;
          .checkbox_para {
            color: #455467;

            font-size: 16px;
            margin: 0;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.32px;
          }

          input {
            width: 13px;
            height: 13px;
            flex-shrink: 0;

            @media (max-width: 768px) {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
            }
          }
        }
      }
      .sign-in-text {
        max-width: 423px;
        color: rgba(16, 23, 41, 0.5);
        font-family: Arial;
        font-size: 10px;
        margin: 0 0 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 145.99%; /* 14.599px */
        text-transform: uppercase;
      }
      .btn_box {
        padding: 48px 0 116px;
        @media screen and(max-width:768px) {
          padding: 48px 0 50px;
        }
        .info-text {
          color: #455467;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.32px;
          margin: 24px 0 0 0;
        }
      }
      .submit_checkbox {
        display: flex;
        margin: 23px 0 0;
        align-items: flex-start;
        gap: 12px;
        input {
          margin-top: 4px;
          width: 13px;
          height: 13px;
          flex-shrink: 0;

          @media (max-width: 768px) {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
          }
        }
        .submit_para {
          max-width: 391px;
          color: #455467;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.32px;
        }
      }
      .sigCanvas {
        background: #fff;
        border-radius: 5px;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
        @media screen and(max-width:480px) {
          width: 100%;
        }
      }
    }
  }
}

.checksApplyWrapperStyle {
  background-color: transparent;

  & p {
    color: #101729;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.formSectionStyleWrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  button {
    width: max-content;
    margin-top: 8px;
  }
}
.rowFormStyle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 26px;

  & .inputWrapperMarginStyle {
    margin: 0 !important;
    width: 100%;
  }

  & .inputWrapperMarginStyle input {
    padding: 13px 12px 12px 25px !important;
    margin: 0px;
    width: 100%;
    height: 39px;
    border: none;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
    color: rgba(16, 23, 41, 0.5);
    font-family: " Arial";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 5px;
    text-align: left !important;
  }

  & .inputWrapperMarginStyle input::placeholder {
    font-size: 14px;
    font-family: "Times New Roman";
  }
  & .inputWrapperMarginStyle input:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
  }
}

.errorColorStyle {
  font-size: 12px;
  text-align: left;
  font-family: Inter-Bold;
  color: #b00707;
  margin-top: 10px;
}

.buttonSubmitStyle {
  margin-top: 50px;
  background-color: #ffc107;
  box-shadow: 1px 15px 30px rgba(132, 132, 132, 0.4);
  color: #ffffff;
  width: 100%;
  padding: 30px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  font-family: Inter-Bold;

  cursor: pointer;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: orange !important;
}

@media screen and (max-width: 600px) {
  .formSectionStyleWrapper {
    margin-top: 30px;
  }
}

@media screen and (max-width: 400px) {
  .formSectionStyleWrapper {
    margin-top: 30px;
  }

  .rowFormStyle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .buttonSubmitStyle {
    margin-top: 50px;
    background-color: #ffc107;
    box-shadow: 1px 15px 30px rgba(132, 132, 132, 0.4);
    color: #ffffff;
    width: 100%;
    padding: 30px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
  }
}

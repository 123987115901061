@import "src/Assets/Scss/variables";

.landingMainOuterWrapper {
  height: 830px;
  background-image: url("../../Assets/Images/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.overlayLoaderWrapper {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
}

.landingBannerContentWrapper {
  //background: red;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h3 {
    font-size: 58px;
    font-family: "Suwannaphum";
    color: #ffffff;
    font-weight: 900;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.53);

    & span {
      font-size: 43px;
      font-family: "Suwannaphum";
      font-weight: bold;
    }
  }
  & p {
    font-size: 28px;

    color: #ffffff;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 600;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    letter-spacing: 0.56px;
  }
}

.supportMattersSection {
  max-width: 1440px;
  padding: 83px 70px 0;
  background: #ffffff;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 83px 20px 0;
  }

  & h4 {
    color: #101729;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 60px;
  }
  .voteSection {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 1320px) {
      gap: 28px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .leftData {
      display: flex;
      flex-direction: row;
      gap: 59px;
      margin-bottom: 34px;

      @media (max-width: 1024px) {
        margin-bottom: 0;
      }

      @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
      }
    }
    .rightData {
      display: flex;

      gap: 59px;
      margin-bottom: 34px;

      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
  }
}

//Featured Rentals Section Styling Start

.featuredRentalSectionWrapper {
  background: #ffffff;
  padding: 95px 141px;
  max-width: 1440px;
  margin: 0 auto;
}

.featuredRentalContentWrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 60px;

  & h2 {
    color: #ffc107;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
    margin: 0 0 19px;
  }
  & p {
    font-family: "Times New Roman";
    color: #c28a60;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    margin: 0;
  }
}

.rentalCardsFlexWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

@media screen and (max-width: 748px) {
  .featuredRentalSectionWrapper {
    background: #ffffff;
    padding: 20px;
  }
  .rentalCardsFlexWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
  }
}

//Slide Toggle Section Styling

.sliderToggleWrapper {
  //background-color: gray;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  position: absolute;
  bottom: 5%;
}

.centerSlideImageWrapper img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1050px) {
  .leftData {
    gap: 28px !important;
  }
  .rightData {
    gap: 28px !important;
  }
}

.toggleCoupleFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.centerSlideImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .landingMainOuterWrapper {
    height: auto;
    background-image: url("../../Assets/Images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 70px;
  }

  // .centerSlideImageWrapper img {
  //   width: 100%;
  // }
  .sliderToggleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    bottom: 5%;
  }
  .toggleCoupleFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .landingBannerContentWrapper {
    //background: red;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;

    & h3 {
      font-size: 43px;
      font-family: "Suwannaphum";
      color: #ffffff;
      text-align: center;
      font-weight: 500;

      & span {
        font-size: 43px;
        font-family: "Suwannaphum";
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .landingMainOuterWrapper {
    height: auto;
    background-image: url("../../Assets/Images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 40px;
  }

  // .centerSlideImageWrapper img {
  //   width: 70%;
  // }

  .sliderToggleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    bottom: 5%;
  }
  .toggleCoupleFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .landingBannerContentWrapper {
    //background: red;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;

    & h3 {
      font-size: 33px;
      font-family: "Suwannaphum";
      color: #ffffff;
      text-align: center;
      font-weight: 500;

      & span {
        font-size: 33px;
        font-family: "Suwannaphum";
        font-weight: bold;
      }
    }

    & p {
      font-size: 16px;

      color: #ffffff;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 400px) {
  .landingMainOuterWrapper {
    height: auto;
    background-image: url("../../Assets/Images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 40px;
  }

  // .centerSlideImageWrapper img {
  //   width: 50%;
  // }

  .sliderToggleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    bottom: 5%;
  }
  .toggleCoupleFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
  }

  .landingBannerContentWrapper {
    //background: red;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;

    & h3 {
      font-size: 28px;
      font-family: "Suwannaphum";
      color: #ffffff;
      text-align: center;
      font-weight: 500;

      & span {
        font-size: 28px;
        font-family: "Suwannaphum";
        font-weight: bold;
      }
    }

    & p {
      font-size: 14px;

      color: #ffffff;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 380px) {
  .landingMainOuterWrapper {
    height: auto;
    background-image: url("../../Assets/Images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 40px;
  }

  // .centerSlideImageWrapper img {
  //   width: 50%;
  // }

  .sliderToggleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    bottom: 5%;
  }
  .toggleCoupleFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  .toggleCoupleFlex div {
    padding: 10px;
  }

  .landingBannerContentWrapper {
    //background: red;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;

    & h3 {
      font-size: 20px;
      font-family: "Suwannaphum";
      color: #ffffff;
      text-align: center;
      font-weight: 500;

      & span {
        font-size: 20px;
        font-family: "Suwannaphum";
        font-weight: bold;
      }
    }

    & p {
      font-size: 12px;

      color: #ffffff;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

//Living Secton Styling Start

.livingSectionWrapper {
  background-image: radial-gradient(#003580 10%, #101729 50%, #101729);
  padding: 40px;
  padding-top: 100px;

  & h3 {
    font-size: 30px;
    font-weight: bold;

    text-align: center;
    letter-spacing: 2px;
    word-spacing: 4px;
    color: #ffffff;
  }
}
.livingComponentWrapperMain {
  margin-top: 40px;
  margin-bottom: 40px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

@media screen and (max-width: 980px) {
  .livingComponentWrapperMain {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .livingSectionWrapper {
    background-image: radial-gradient(#003580 10%, #101729 50%, #101729);
    padding: 40px;
    padding-top: 100px;

    & h3 {
      font-size: 24px;
      font-weight: bold;

      text-align: center;
      letter-spacing: 2px;
      word-spacing: 4px;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 367px) {
  .livingSectionWrapper {
    background-image: radial-gradient(#003580 10%, #101729 50%, #101729);
    padding: 20px;
    padding-top: 50px;

    & h3 {
      font-size: 20px;
      font-weight: bold;

      text-align: center;
      letter-spacing: 1px;
      word-spacing: 2px;
      color: #ffffff;
    }
  }
}

//Club Styling Start

.clubSectionWrapper {
  background: linear-gradient(90deg, #7b89a5, #cbdeed 60%);
  padding: 60px;
}

.clubContentMainWrapper {
  //background: red;
  padding: 30px;

  & h3 {
    font-size: 40px;

    font-weight: 600;
    color: #ffffff;
  }
}

.listsClubsWrapper {
  margin-top: 50px;

  ul li {
    font-size: 18px;

    font-weight: 600;
    line-height: 25px;
    color: #ffffff;
  }
}

.campusStyleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 90px;
}
.campusImageStyle {
  //background: red;
  width: 80%;
}
.campusImageStyle img {
  width: 80%;
}

.campusContentWrapper h4 {
  font-size: 22px;

  font-weight: 600;
  color: #ffffff;
}
.campusContentWrapper p {
  font-size: 12px;

  font-weight: 600;
  color: #ffffff;
}

.clubImageWrapper {
  padding: 50px;
  //background: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clubImageWrapper img {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .listsClubsWrapper {
    margin-top: 50px;

    ul li {
      font-size: 22px;

      font-weight: 600;
      line-height: 30px;
      color: #ffffff;
    }
  }

  .campusImageStyle {
    width: 50%;
  }
  .campusImageStyle img {
    width: 50%;
  }

  .clubImageWrapper {
    padding: 40px;
    //background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clubImageWrapper img {
    width: 60%;
  }
}

@media screen and (max-width: 672px) {
  .clubSectionWrapper {
    background: linear-gradient(90deg, #7b89a5, #cbdeed 60%);
    padding: 20px;
  }

  .listsClubsWrapper {
    margin-top: 50px;

    ul li {
      font-size: 18px;

      font-weight: 600;
      line-height: 30px;
      color: #ffffff;
    }
  }

  .campusImageStyle {
    width: 70%;
  }
  .campusImageStyle img {
    width: 70%;
  }

  .clubImageWrapper {
    padding: 40px;
    //background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clubImageWrapper img {
    width: 60%;
  }
}

@media screen and (max-width: 490px) {
  .clubContentMainWrapper {
    //background: red;
    padding: 10px;

    & h3 {
      font-size: 28px;

      font-weight: 600;
      color: #ffffff;
    }
  }
  .clubSectionWrapper {
    background: linear-gradient(160deg, #7b89a5, #cbdeed 90%);
    padding: 20px;
  }

  .listsClubsWrapper {
    margin-top: 20px;

    ul li {
      font-size: 16px;

      font-weight: 600;
      line-height: 30px;
      color: #ffffff;
      margin-left: -20px;
    }
  }

  .campusImageStyle {
    width: 90%;
  }
  .campusImageStyle img {
    width: 90%;
  }

  .clubImageWrapper {
    padding: 40px;
    //background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clubImageWrapper img {
    width: 80%;
  }
}

@media screen and (max-width: 390px) {
  .clubContentMainWrapper {
    //background: red;
    padding: 10px;

    & h3 {
      font-size: 26px;

      font-weight: 600;
      color: #ffffff;
    }
  }
  .clubSectionWrapper {
    background: linear-gradient(160deg, #7b89a5, #cbdeed 90%);
    padding: 20px;
  }

  .listsClubsWrapper {
    margin-top: 20px;

    ul li {
      font-size: 14px;

      font-weight: 600;
      line-height: 30px;
      color: #ffffff;
      margin-left: -20px;
    }
  }
  .campusStyleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
  }

  .campusImageStyle {
    width: 70%;
    margin-bottom: 20px;
  }
  .campusImageStyle img {
    width: 70%;
  }

  .clubImageWrapper {
    padding: 40px;
    //background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clubImageWrapper img {
    width: 80%;
  }
}

//westle home school styling start

.westleHomeSectionWrapper {
  background: #ffffff;
  padding: 111px 70px 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  gap: 99px;
}

@media screen and (max-width: 1040px) {
  .westleHomeSectionWrapper {
    background: #ffffff;
    padding: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}

@media screen and (max-width: 880px) {
  .westleHomeSectionWrapper {
    background: #ffffff;
    padding: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
}

//Friendly Section Styling Start

.friendSectionMainWrapper {
  background: transparent;
  padding: 75px 82px 0;
  max-width: 1440px;
  margin: 0 auto;

  & p {
    padding-left: 22px;
  }
}

.headingFriendlySection {
  margin-bottom: 123px;
  & h3 {
    color: #ffc107;

    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    margin: 0;
  }
}
.cardFriendlyWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 39px;
  gap: 16px;
}

.labelFriendlySection {
  p {
    color: #101729;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
}
.wrapCarStyle {
  @media (max-width: 600px) {
    width: 100%;
  }

  & p {
    color: #dee9f2;

    font-size: 220px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 4.4px;
    // position: absolute;
  }
}

@media screen and (max-width: 500px) {
  .friendSectionMainWrapper {
    background: transparent;
    padding: 30px;
  }

  .headingFriendlySection {
    & h3 {
      font-size: 26px;

      font-weight: 600;
      text-align: center;
      color: #ffc107;
    }
  }
}

@media screen and (max-width: 330px) {
  .friendSectionMainWrapper {
    background: transparent;
    padding: 20px;
  }

  .headingFriendlySection {
    & h3 {
      font-size: 22px;

      font-weight: 600;
      text-align: center;
      color: #ffc107;
    }
  }
}

//Read More button styling
.readMoreButtonWrapper {
  background-color: rgba(0, 0, 0, 0.2);
  border: 3px solid white;
  cursor: pointer;
  border-radius: 50px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 12px;
  padding-bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkReadMore {
  text-decoration: none !important;
}
.readMoreButtonWrapper p {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none !important;
  color: #ffffff;
  padding-right: 10px;
}
.arrowrightIcon {
  margin-top: -5px;
}

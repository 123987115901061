.main_proposal {
  background: #e0edff;
  .proposal_btn {
    padding: 93px 0 102px;
  }
}
.proposal_container {
  //padding-top: 161px !important;
  // margin-top: 55.73px;

  @media (max-width: 768px) {
    // padding-top: 62px !important;
  }
}

@import "../../Assets/Scss/variables";

.friendlyCardWrapperStyle {
  width: 253px;
  padding: 35px 26px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 22px;
  background: #fff;
  box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.25);
}

.friendlyTopWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h3 {
    color: #6d767e;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    z-index: 1;
    margin-bottom: 13px;
  }
  & p {
    color: #999b9e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 0;
    margin-bottom: 17px;
  }

  .phaseNumber {
    color: #dee9f2;
    font-size: 220px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 4.4px;
    padding-left: 110px;
    padding-top: 50px;
  }
}

.friendlyCenterWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;

  & span {
    color: #003580;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
  }

  & .campusFriendlyHeading {
    color: #003580;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    margin: 5px 0;
  }
  & .campusAddressFriendly {
    color: #101729;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 0;
    margin: 5px 0 24px;
  }
}

.targetImageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;

  & img {
    width: 86px;
    height: 130.8px;
  }
}

.friendlyButtonWrapper {
  width: 100%;
  color: #fff;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  text-align: center;

  padding: 2px;
  cursor: pointer;
  border-radius: 15px;
  background: #c28a60;
}

@media screen and (max-width: 500px) {
  .friendlyCardWrapperStyle {
    width: 100%;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .friendlyTopWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & h3 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #6d767e;
    }
    & p {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #999b9e;
    }
  }

  .friendlyCenterWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    & span {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #003580;
      //padding:2px;
    }

    & .campusFriendlyHeading {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #003580;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    & .campusAddressFriendly {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #000;
      margin-top: 20px;
    }
  }
  .targetImageWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 10px;

    & img {
      width: 30%;
    }
  }
}

@media screen and (max-width: 330px) {
  .friendlyCardWrapperStyle {
    width: 100%;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .friendlyTopWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & h3 {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #6d767e;
      z-index: 1;
    }
    & p {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #999b9e;
    }
  }

  .friendlyCenterWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    & span {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #003580;
      //padding:2px;
    }

    & .campusFriendlyHeading {
      font-size: 14px;
      font-family: Inter-Bold;
      font-weight: bold;
      text-align: center;
      color: #003580;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    & .campusAddressFriendly {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #000;
      margin-top: 20px;
    }
  }
  .targetImageWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 10px;
    & img {
      width: 40%;
    }
  }
}

@import "../../Assets/Scss/variables";

.footerMainOuterWrapper {
  background: #fff;
  height: 300px;
  width: 100%;
  padding: 50px 120px 20px;

  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 992px) {
    padding: 50px 80px 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 50px 24px 20px;
  }

  .inner-container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    gap: 12px;
  }
}
.footerLogoImageWrapper {
  display: flex;
  justify-content: center;
}
.footer_logo_div {
  display: flex;
  flex-direction: column;
  gap: 22px;
  .footerLogoImageWrapper img {
    width: 98px;
    height: 58.843px;
    flex-shrink: 0;
  }
  .logo_text {
    max-width: 269px;
    color: #101729;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    margin: 0;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

.footerContentWrapper {
  & h2 {
    font-size: 20px;
    color: #101729;
    font-family: "Roboto";
  }
}

.footerContentPointerWrapper {
  margin-top: 40px;
}
.footerIconWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;

  & p a {
    font-size: 20px;
    color: #6d767e;
    font-family: "Roboto";
    text-decoration: none;
    margin-left: 20px;
  }
}
@media screen and (max-width: 903px) {
  .footerContentWrapper {
    & h2 {
      font-size: 20px;
      color: #101729;
      font-family: "Roboto" !important;
    }
  }
}

@media screen and (max-width: 650px) {
  .footerLogoImageWrapper {
    margin-left: 0px;
  }

  .footerMainOuterWrapper {
    background: #fff;
    height: 400px;
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
  }

  .footerContentWrapper {
    & h2 {
      font-size: 20px;
      color: #101729;
      font-family: "Roboto";
    }
  }

  .footerIconWrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;

    & p a {
      font-size: 14px;
      color: #6d767e;
      font-family: "Roboto";
      text-decoration: none;
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 400px) {
  .footerLogoImageWrapper {
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .footerMainOuterWrapper {
    background: #fff;
    height: 350px;
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
  }

  .footerContentWrapper {
    margin-left: 0px;
    & h2 {
      font-size: 20px;
      color: #101729;
      font-family: "Roboto";
    }
  }
}

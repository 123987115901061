@import "../../Assets/Scss/variables";

// .header-wapper {
//   width: 100%;
//   height: auto;
//   margin-top: 107px;
// }

.headerMainOuterWrapper {
  height: 107px;
  width: 100%;
  background: #fff;

  position: relative;
  top: 0;
  left: 0;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .header-content-wrapper {
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 148px 27px 59px;
    background: #fff;

    @media (max-width: 1052px) {
      padding: 27px;
    }

    .logoImageWrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      position: relative;
      text-decoration: none;

      .logo {
        width: 89px;
        height: auto;
      }

      .logo-name {
        color: #0f0f10;
        text-align: left;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.32px;
        margin: 0;
      }
    }

    // btn
    .menu-btns {
      position: relative;
      width: 28px;
      height: auto;
      cursor: pointer;
      @media (min-width: 992px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    //
    .nav-links {
      display: flex;
      align-items: center;
      gap: 44px;

      @media (max-width: 992px) {
        display: none;
      }

      .nav-link {
        color: #555;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.32px;
        margin: 0;
        text-decoration: none;
        cursor: pointer;
        position: relative;
      }

      .nav-a {
        margin-left: 21px;
      }

      .nav-dropdown {
        position: relative;
        .drop-name {
          display: flex;
          align-items: flex-start;
          gap: 14px;
          cursor: pointer;

          span {
            img {
              width: 10px;
              height: 5px;
            }
          }
        }

        .menu-devider {
          width: 100%;
          height: 45px;
          background-color: #003580;
          position: absolute;
        }

        // menu

        .dropdown-menu {
          display: block;
          width: auto;
          min-height: 185px;
          border-radius: 5px;
          padding: 47px 49px 29px 49px;
          text-align: center;
          transition: all 250ms linear;
          background: #fff;
          box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
          position: absolute;

          left: 50%;
          transform: translateX(-50%);
          z-index: -1;

          &.show {
            top: 43px;
          }

          &.hidden {
            top: -200px;
          }

          .menu-item-box {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 26px;

            .menu-item {
              white-space: nowrap;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

//
.formal-main-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px 0;
  @media screen and (max-width: 768px) {
    padding: 0 24px 0;
  }

  &.blog-header-wrapper {
    padding: 0 70px 0;

    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .formal-header {
    max-width: 676px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 52px 0 84px;
    @media screen and (max-width: 768px) {
      padding: 52px 0 60px;
    }

    &.blog-header {
      align-items: flex-start !important;
      flex-direction: column-reverse !important;
      gap: 52px !important;
      padding: 24px 0 52px;
    }

    .back-btn {
      display: flex;
      text-decoration: none;
      cursor: pointer;
      align-items: center;
      gap: 21px;
      @media screen and (max-width: 380px) {
        gap: 12px;
      }

      p {
        color: #585858;
        text-align: center;

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
        margin: 0;
      }
    }
  }
}

// mobile menu

.mobile-menu {
  position: absolute;
  top: 107px;
  right: -1px;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 32px 40px;
  transition: all 250ms linear;

  &.active-menu {
    right: 0;
  }
  @media (min-width: 992px) {
    display: none;
  }

  .nav-links {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;

    .nav-link {
      color: #555;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.32px;
      margin: 0;
      text-decoration: none;
      cursor: pointer;
      position: relative;
    }

    .nav-a {
      margin-left: 0;
    }

    .nav-dropdown {
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
      .drop-name {
        display: flex;
        align-items: flex-start;
        gap: 14px;
        cursor: pointer;

        span {
          img {
            width: 10px;
            height: 5px;
          }
        }
      }

      .menu-devider {
        width: 100%;
        height: 45px;
        background-color: #003580;
        position: absolute;
      }

      // menu

      .dropdown-menu {
        display: block;
        width: auto;
        border-radius: 5px;
        padding: 47px 49px 29px 49px;
        text-align: left;
        transition: all 250ms linear;
        background: #fff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        @media (max-width: 768px) {
          width: 100%;
        }

        &.show {
          height: auto;
          padding: 28px 20px;
          margin-top: 16px;
        }

        &.hidden {
          height: 0;
          padding: 0 20px;
          margin-top: 0 !important;
        }

        .menu-item-box {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 26px;

          .menu-item {
            white-space: nowrap;
            margin: 0;
          }
        }
      }
    }
  }
}
